<template>
  <div class="main-area">
    <main>
      <section class="main-title">
        <h1>チャレンジ詳細</h1>
      </section>

      <!-- チャレンジ詳細(new)-->
      <section class="article-container mt-3">
        <article class="white-block reading-block">
          <!-- メインビジュアル画像 -->
          <div class="reading-visual">
            <template v-if="!isObjectEmpty(provisional_ep_challenge.main_image)">
              <a class="img-box" :href="provisional_ep_challenge.main_image.presigned_url"
                data-lightbox="challenge_image" :data-title="provisional_ep_challenge.main_image_title">
                <img
                  :src="provisional_ep_challenge.main_image.presigned_url"
                  :alt="provisional_ep_challenge.main_image.filename">
              </a>
              <div class="reading-visual-caption">{{ provisional_ep_challenge.main_image_title }}</div>
            </template>
            <template v-else>
              <a class="img-box" href="#">
                <img
                  src="~images/image_default.jpg"
                  alt="デフォルト画像"
                />
              </a>
            </template>
          </div>
          <!-- 投稿日時 -->
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ provisional_ep_challenge.created_at | format.datetime }}</small>
          </div>
          <!-- タイトル -->
          <h2 class="reading-title">{{ provisional_ep_challenge.title }}</h2>
          <!-- 最新情報企業 -->
          <dl class="reading-body border-bottom">
            <dt>
              <img v-if="provisional_ep_challenge.is_challenge_manager_image"
                :src="provisional_ep_challenge.presigned_challenge_manager_image_url"
                :alt="provisional_ep_challenge.presigned_challenge_manager_image_filename"
                class="thumbnail-frame img40"
              />
              <img v-else
                src="~images/ep_user_default.png"
                alt="画像が選択されていません"
                class="thumbnail-frame img40"
              />
            </dt>
            <dd>
              <p v-if="provisional_ep_challenge.is_deleted"><span class="badge badge-sm badge-danger">削除</span></p>
                {{ !!provisional_ep_challenge.ep_company ? provisional_ep_challenge.ep_company_name_department_name : '' }}
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>私たちのチャレンジ</dt>
            <dd class="u-pre-wrap ql-snow">
              <div class="ql-editor" v-html="provisional_ep_challenge.detail"></div>
            </dd>
          </dl>
          <dl class="reading-item" v-if="provisional_ep_challenge.sub1_image_id || provisional_ep_challenge.sub2_image_id">
            <dt>イメージ</dt>
            <dd class="d-flex">
              <!-- 画像（サブビジュアル1） -->
              <figure v-if="provisional_ep_challenge.sub1_image_id">
                <a class="img-box" :href="provisional_ep_challenge.sub1_image.presigned_url"
                  data-lightbox="challenge_image" :data-title="provisional_ep_challenge.sub1_image_title">
                  <img
                    :src="provisional_ep_challenge.sub1_image.presigned_url"
                    :alt="provisional_ep_challenge.presigned_challenge_sub1_image_filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ provisional_ep_challenge.sub1_image_title }}</figcaption>
              </figure>
              <!-- 画像（サブビジュアル2） -->
              <figure class="mr-3" v-if="provisional_ep_challenge.sub2_image_id">
                <a class="img-box" :href="provisional_ep_challenge.sub2_image.presigned_url"
                  data-lightbox="challenge_image" :data-title="provisional_ep_challenge.sub2_image_title">
                  <img
                    :src="provisional_ep_challenge.sub2_image.presigned_url"
                    :alt="provisional_ep_challenge.sub2_image.filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ provisional_ep_challenge.sub2_image_title }}</figcaption>
              </figure>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>パートナー企業様にご提供できる価値</dt>
            <dd>
              <template v-if="provisional_ep_challenge.is_show_more_assets">
                <div v-for="(asset, index) in provisional_ep_challenge.asset_masters" :key="asset.asset_master_id" class="task-labels" v-if="index <= 9">
                  <p class="task-labels-parent" >
                    {{ asset.name }}
                  </p>
                </div>
                <div class="btn-container text-right">
                  <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssets()">
                    もっと見る
                  </button>
                </div>
              </template>
              <template v-else>
                <div v-for="asset in provisional_ep_challenge.asset_masters" :key="asset.asset_master_id" class="task-labels">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
              </template>
            </dd>
          </dl>

          <!-- チャレンジのステージ -->
          <dl class="reading-item">
            <dt>このチャレンジのステータス</dt>
            <dd>
              <div class="status-list">

                <!-- アイデア段階 -->
                <div class="status-item">
                  <div v-if="provisional_ep_challenge.is_idea_stage">
                    <p class="status-label active">アイデア段階</p>
                    <div class="img-box"><img src="~images/status_img1_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">アイデア段階</p>
                    <div class="img-box"><img src="~images/status_img1.svg" alt=""></div>
                  </div>
                </div>

                <!-- PoC段階 -->
                <div class="status-item">
                  <div v-if="provisional_ep_challenge.is_poc_stage">
                    <p class="status-label active">PoC段階</p>
                    <div class="img-box"><img src="~images/status_img2_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">PoC段階</p>
                    <div class="img-box"><img src="~images/status_img2.svg" alt=""></div>
                  </div>
                </div>

                <!-- 事業化段階 -->
                <div class="status-item">
                  <div v-if="provisional_ep_challenge.is_commercial_stage">
                    <p class="status-label active">事業化段階</p>
                    <div class="img-box"><img src="~images/status_img3_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">事業化段階</p>
                    <div class="img-box"><img src="~images/status_img3.svg" alt=""></div>
                  </div>
                </div>

                <!-- その他 -->
                <div class="status-item">
                  <div v-if="provisional_ep_challenge.is_other_stage">
                    <p class="status-label active">その他</p>
                    <div class="img-box"><img src="~images/status_img4_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">その他</p>
                    <div class="img-box"><img src="~images/status_img4.svg" alt=""></div>
                  </div>
                </div>

              </div>
            </dd>
          </dl>

          <dl class="reading-item">
            <dt>企業情報（私たちについて）</dt>
            <dd class="u-pre-wrap" v-if="!!provisional_ep_challenge.ep_company">{{ provisional_ep_challenge.ep_company.business_summary }}</dd>
          </dl>
        </article>
      </section>

      <!-- 審査詳細-->
      <section class="chapter white-block" v-if="isDispExamInfo()">
        <h2 class="chapter-label mb-3">審査詳細</h2>
        <div class="overview-list overview-list-dt15 my-3 p-3">
          <dl class="form-confirm-group">
            <dt>登録種別</dt>
            <dd>{{ provisional_ep_challenge.registed_type_i18n }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>作成日</dt>
            <dd>{{ provisional_ep_challenge.created_at | format.datetime }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>審査ステータス</dt>
            <dd>{{ provisional_ep_challenge.exam_status_i18n }}</dd>
          </dl>

          <dl class="form-confirm-group"  v-if="provisional_ep_challenge.is_modify">
            <dt>要修正理由</dt>
            <dd>{{ provisional_ep_challenge.no_reason }}</dd>
          </dl>

          <dl class="form-confirm-group">
            <dt>レビュー完了日</dt>
            <dd>{{ provisional_ep_challenge.examination_at | format.datetime }}</dd>
          </dl>

          <dl class="form-confirm-group" v-if="!isObjectEmpty(provisional_ep_challenge.ep_challenge) && !provisional_ep_challenge.is_deleted">
            <dt>公開ステータス</dt>
            <dd>{{ provisional_ep_challenge.public_status_i18n }}</dd>
          </dl>

          <dl class="form-confirm-group" v-if="!isObjectEmpty(provisional_ep_challenge.ep_challenge) && !provisional_ep_challenge.is_deleted">
            <dt>公開期間</dt>
            <dd>{{ provisional_ep_challenge.ep_challenge.release_at | format.datetime }} ~ {{ provisional_ep_challenge.ep_challenge.close_at | format.datetime }}</dd>
          </dl>
        </div>
      </section>
      <!-- / 審査詳細-->

      <!-- おすすめチャレンジ枠-->
      <section class="chapter white-block">
        <h2 class="chapter-label mb-3">おすすめチャレンジ枠</h2>
        <div class="overview-list overview-list-dt15 my-3 p-3">
          <dl class="form-confirm-group">
            <dt>おすすめチャレンジ枠</dt>
            <dd>{{ getSponserOptionText(provisional_ep_challenge.is_sponser) }}</dd>
          </dl>
        </div>
      </section>
      <!-- // おすすめチャレンジ枠-->

      <!-- 外部公開フラグ-->
      <section class="chapter white-block">
        <h2 class="chapter-label mb-3">外部公開フラグ</h2>
        <div class="overview-list overview-list-dt15 my-3 p-3">
          <dl class="form-confirm-group">
            <dt>外部公開フラグ</dt>
            <dd>{{ getPublicOptionText(provisional_ep_challenge.is_public) }}</dd>
          </dl>
          <dl class="form-confirm-group" v-if="provisional_ep_challenge.is_public">
            <dt>外部公開URL</dt>
            <dd><a :href="`${fullURL}/su/challenges/public/${provisional_ep_challenge.ep_challenge_id}`" target="_blank" rel="noopener noreferrer">{{ fullURL }}/su/challenges/public/{{ provisional_ep_challenge.ep_challenge_id }}</a>
            </dd>
          </dl>
        </div>
      </section>
      <!-- // 外部公開フラグ-->

      <!-- ボタンエリア-->
      <div class="btn-container adjust-lotof-btn d-flex justify-content-center">
        <a class="btn btn-big btn-white rounded-pill"  @click.prevent.stop="onClickBack">戻る</a>
        <button
          class="btn btn-big btn-delete rounded-pill"
          :class="provisional_ep_challenge.is_deleted ? 'disable' : ''"
          :disabled="provisional_ep_challenge.is_deleted"
          @click.prevent.stop="onDelete"
          style="margin-left: 10px;"
        >
          削除する
        </button>
        <button
          class="btn btn-big btn-green rounded-pill"
          :class="(provisional_ep_challenge.is_close || provisional_ep_challenge.is_deleted) ? 'disable' : ''"
          :disabled="(provisional_ep_challenge.is_close || provisional_ep_challenge.is_deleted)"
          @click.prevent.stop="onEdit"
        >
          更新する
        </button>
      </div>
      <template
        v-if="provisional_ep_challenge.is_edit &&
              !provisional_ep_challenge.is_deleted &&
              !!provisional_ep_challenge.ep_challenge.id &&
              (provisional_ep_challenge.is_undecided || provisional_ep_challenge.is_modify || provisional_ep_challenge.is_reregister)"
      >
        <button class="button-square btn-diff" @click="onDiffView()">承認中のチャレンジと見比べる<img src="~images/icon_diff_window.svg"></button>
      </template>
      <!-- ボタンエリア-->

      <ConfirmModal
        ref="delete_confirm_modal"
        :onClickSend="onDeleteComfirmSend"
        :body_text="'このチャレンジを削除します。<br>よろしいですか？'"
        :send_button_text="'削除する'"
        :isNoFade="isNoFade"
      />

      <ConfirmModal
        ref="exam_confirm_modal"
        :onClickSend="onSaveComfirmSend"
        :body_text="'審査内容を登録します。<br>よろしいですか？'"
        :send_button_text="'登録する'"
        :isNoFade="isNoFade"
      />

    </main>

    <!-- 審査フォーム -->
    <div class="fixed-footer-block" v-show="isDispExamForm()">
      <div class="inner">
        <div class="chapter-label my-1">チャレンジの承認を行う</div>

        <form class="form-wrap">
          <div class="properties-wrap">
            <!-- 審査ステータス -->
            <div class="form-group properties-item">
              <label class="properties-item-label">審査ステータス</label>
              <div class="properties-item-value">
                <b-select v-model="form.provisional_ep_challenge.exam_status" :state="checkValid('exam_status')" class="custom-select">
                  <option v-for="(exam_statuses_i18n, exam_status_key) in exam_statuses" :key="exam_status_key" :value="exam_status_key" v-if="exam_status_key != 'draft'">
                    {{exam_statuses_i18n}}
                  </option>
                </b-select>
              </div>
              <div v-for="error_message in validation_errors.exam_status" class="invalid-feedback" style="display: block">
                {{ error_message }}
              </div>
            </div>
            <!-- 公開ステータス -->
            <div class="form-group properties-item" v-show="isDispPublicArea()">
              <label class="properties-item-label">公開ステータス</label>
              <div class="properties-item-value">
                <p>{{ form.provisional_ep_challenge.public_status }}</p>
              </div>
            </div>
            <!-- 公開開始日時 -->
            <div class="form-group properties-item" v-show="isDispPublicArea()">
              <label class="properties-item-label">公開開始日時</label>
              <div class="properties-item-value">
                <!-- 日付 -->
                <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                  <b-form-input
                    v-model="form.provisional_ep_challenge.ep_challenge_attributes.release_date"
                    :state="checkValid('ep_challenge.release_date')"
                    class="form-control"
                    id="start-date"
                    type="text"
                    data-toggle="datetimepicker"
                    data-target="#start-date"
                    autocomplete="off"
                    placeholder="日付"
                    required
                  ></b-form-input><i class="far fa-calendar-alt"></i>
                  <div v-if="!!validation_errors['ep_challenge.release_date']" class="invalid-feedback d-block">
                    {{ validation_errors['ep_challenge.release_date'][0] }}
                  </div>
                </div>
                <!-- 時間 -->
                <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                  <b-form-input
                    v-model="form.provisional_ep_challenge.ep_challenge_attributes.release_time"
                    :state="checkValid('ep_challenge.release_time')"
                    class="form-control"
                    id="start-time"
                    type="text"
                    data-toggle="datetimepicker"
                    data-target="#start-time"
                    autocomplete="off"
                    placeholder="時間"
                    required
                  ></b-form-input><i class="far fa-clock"></i>
                  <div v-if="!!validation_errors['ep_challenge.release_time']" class="invalid-feedback d-block">
                    {{ validation_errors['ep_challenge.release_time'][0] }}
                  </div>
                </div>
                <div v-if="!!validation_errors['ep_challenge.release_at']" class="invalid-feedback d-block">
                  {{ validation_errors['ep_challenge.release_at'][0] }}
                </div>
              </div>
            </div>

            <!-- 公開終了日時 -->
            <div class="form-group properties-item" v-show="isDispPublicArea()">
              <label class="properties-item-label">公開終了日時</label>
              <div class="properties-item-value">
                <!-- 日付 -->
                <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                  <b-form-input
                    v-model="form.provisional_ep_challenge.ep_challenge_attributes.close_date"
                    :state="checkValid('ep_challenge.close_date')"
                    class="form-control"
                    id="end-date"
                    type="text"
                    data-toggle="datetimepicker"
                    data-target="#end-date"
                    autocomplete="off"
                    placeholder="日付"
                  ></b-form-input><i class="far fa-calendar-alt"></i>
                  <div v-if="!!validation_errors['ep_challenge.close_date']" class="invalid-feedback d-block">
                    {{ validation_errors['ep_challenge.close_date'][0] }}
                  </div>
                </div>
                <!-- 時間 -->
                <div class="input-group icon-addon-right datetimepicker-input custom-control-inline">
                  <b-form-input
                    v-model="form.provisional_ep_challenge.ep_challenge_attributes.close_time"
                    :state="checkValid('ep_challenge.close_time')"
                    class="form-control"
                    id="end-time"
                    type="text"
                    data-toggle="datetimepicker"
                    data-target="#end-time"
                    autocomplete="off"
                    placeholder="時間"
                  ></b-form-input><i class="far fa-clock"></i>
                  <div v-if="!!validation_errors['ep_challenge.close_time']" class="invalid-feedback d-block">
                    {{ validation_errors['ep_challenge.close_time'][0] }}
                  </div>
                </div>
                <div v-if="!!validation_errors['ep_challenge.close_at']" class="invalid-feedback d-block">
                  {{ validation_errors['ep_challenge.close_at'][0] }}
                </div>
              </div>
            </div>

            <div class="form-group properties-item flex-grow-1" v-show="isDispNoReason() && !isDispPublicArea()">
            </div>

            <div class="form-group properties-item flex-grow-1" v-show="isDispNoReason()">
              <label class="properties-item-label" for="rejected-comment">要修正理由</label>
              <div class="properties-item-value">
                <b-form-textarea
                  v-model="form.provisional_ep_challenge.no_reason"
                  :state="checkValid('no_reason')"
                  type="text"
                  placeholder=""
                  class="form-control">
                </b-form-textarea>
                <small class="form-text text-right">500文字以内</small>
                <div v-if="!!validation_errors['no_reason']" class="invalid-feedback" style="display: block">
                  {{ validation_errors['no_reason'][0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="btn-container d-flex justify-content-end">
            <button
              class="button-square btn-send btn-sm"
              :class="form.provisional_ep_challenge.exam_status == 'undecided' ? 'disabled' : ''"
              type="button" data-toggle="modal" data-target="#register" @click="onSave()">登録する</button>
          </div>
        </form>
      </div>
    </div>
    <!-- 審査フォーム -->

    <FooterComponent />
  </div>
</template>

<script>
  // Footer
  import FooterComponent from '../../../common/layouts/footer';

  import Form from '../../../common/mixins/form'
  // 確認モーダルのコンポーネント読み込み
  import ConfirmModal from '../../../common/pages/ConfirmModal.vue';

  export default {
    mixins: [Form],
    metaInfo: {
      title: 'チャレンジ詳細',
    },

    components: {
      ConfirmModal,
      FooterComponent
    },

    data() {
      return {
        fullURL: `${window.location.protocol}//${window.location.host}`,
        provisional_ep_challenge: {},
        ep_challenge_updated_at: '',
        previous_route: null,
        validation_errors: {},
        sponserOptions: [
          { value: 0, text: '設定しない' },
          { value: 1, text: '設定する' }
        ],
        publicOptions: [
          { value: false, text: '公開しない' },
          { value: true, text: '公開する' }
        ],
        // 審査ステータス一覧
        exam_statuses: {},
        form: {
          provisional_ep_challenge: {
            id: '',
            exam_status: '',
            no_reason: '',
            public_status: '',
            updated_at: '',
            // EPチャレンジ
            ep_challenge_attributes: {
              id: '',
              release_date: '',
              close_date: '',
              release_time: '',
              close_time: '',
              release_at: '',
              close_at: '',
            }
          },
        },
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    mounted: function() {
      const self = this;
      // 公開期間_開始日 のカレンダー設定
      this.setDatePicker(
        '#start-date',
        function(selected_date) {
          self.form.provisional_ep_challenge.ep_challenge_attributes.release_date = selected_date;
          self.updatePublicStatus();
        },
        'top'
      );
      // 公開期間_終了日 のカレンダー設定
      this.setDatePicker(
        '#end-date',
        function(selected_date) {
          self.form.provisional_ep_challenge.ep_challenge_attributes.close_date = selected_date;
          self.updatePublicStatus();
        },
        'top'
      );
      // 公開期間_開始時間 のカレンダー設定
      this.setTimePicker(
        '#start-time',
        function(selected_time) {
          self.form.provisional_ep_challenge.ep_challenge_attributes.release_time = selected_time;
          self.updatePublicStatus();
        },
        'top'
      );
      // 公開期間_終了時間 のカレンダー設定
      this.setTimePicker(
        '#end-time',
        function(selected_time) {
          self.form.provisional_ep_challenge.ep_challenge_attributes.close_time = selected_time;
          self.updatePublicStatus();
        },
        'top'
      );
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getProvisionalEpChallenge();
      },
      getSponserOptionText(value) {
        const option = this.sponserOptions.find(option => option.value === value);
        return option ? option.text : '未設定';
      },
      getPublicOptionText(value) {
        const public_op = this.publicOptions.find(public_op => public_op.value === value);
        return public_op ? public_op.text : '未設定';
      },
      getProvisionalEpChallenge() {
        this.$loading.load(
          this.$auth.api.get(
            `admin/provisional_ep_challenges/${this.$route.params.id}`
          ).then(res => {
            this.provisional_ep_challenge = res.data.provisional_ep_challenge;
            this.exam_statuses = res.data.exam_statuses;

            // ID
            this.form.provisional_ep_challenge.id            = this.provisional_ep_challenge.id;
            // 審査ステータス
            this.form.provisional_ep_challenge.exam_status   = this.provisional_ep_challenge.exam_status;
            // 公開ステータス
            this.form.provisional_ep_challenge.public_status = this.provisional_ep_challenge.public_status_i18n;
            // 要修正理由
            this.form.provisional_ep_challenge.no_reason     = this.provisional_ep_challenge.no_reason;
            // 更新日時
            this.form.provisional_ep_challenge.updated_at    = this.provisional_ep_challenge.updated_at;

            if(!!this.provisional_ep_challenge.ep_challenge) {
              // EPチャレンジID
              this.form.provisional_ep_challenge.ep_challenge_attributes.id = this.provisional_ep_challenge.ep_challenge.id;
              // 公開期間 開始
              this.form.provisional_ep_challenge.ep_challenge_attributes.release_at   = this.provisional_ep_challenge.ep_challenge.release_at;
              this.form.provisional_ep_challenge.ep_challenge_attributes.release_date = this.provisional_ep_challenge.ep_challenge.release_date;
              this.form.provisional_ep_challenge.ep_challenge_attributes.release_time = this.provisional_ep_challenge.ep_challenge.release_time;
              // 公開期間 終了
              this.form.provisional_ep_challenge.ep_challenge_attributes.close_at   = this.provisional_ep_challenge.ep_challenge.close_at;
              this.form.provisional_ep_challenge.ep_challenge_attributes.close_date = this.provisional_ep_challenge.ep_challenge.close_date;
              this.form.provisional_ep_challenge.ep_challenge_attributes.close_time = this.provisional_ep_challenge.ep_challenge.close_time;
            }

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },

      // 戻るボタン押下時処理
      onClickBack() {
        if (!!this.previous_route && this.previous_route.name === 'AdminProvisionalEpChallengeIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        } else {
          this.$router.push({name: 'AdminProvisionalEpChallengeIndex'});
        }
      },

      // 削除ボタン押下時
      onDelete() {
        if (!!this.provisional_ep_challenge.ep_challenge) {
          this.ep_challenge_updated_at = this.provisional_ep_challenge.ep_challenge.updated_at;
        }
        this.$refs.delete_confirm_modal.open();
      },

      // 削除確認モーダル内の削除するボタン押下時
      onDeleteComfirmSend() {
        if (!!this.provisional_ep_challenge.ep_challenge) {
          this.ep_challenge_updated_at = this.provisional_ep_challenge.ep_challenge.updated_at;
        }
        this.$loading.load(
          this.$auth.api.delete(
            'admin/provisional_ep_challenges/' + this.provisional_ep_challenge.id, {
              params: {
                provisional_ep_challenge_updated_at: this.provisional_ep_challenge.updated_at,
                ep_challenge_updated_at: this.ep_challenge_updated_at
              }
            }
          ).then(res => {
            this.$refs.delete_confirm_modal.close();
            // 一覧画面へ戻る
            if (!!this.previous_route && this.previous_route.name === 'AdminProvisionalEpChallengeIndex') {
              this.$router.push({path: this.previous_route.fullPath, query: {toast_type: 'deleted'}});
            } else {
              this.$router.push({name: 'AdminProvisionalEpChallengeIndex', query: {toast_type: 'deleted'}});
            }
          }).catch(err => {
            this.$refs.delete_confirm_modal.close();

            if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        )
      },
      // 更新するボタン押下時
      onEdit() {
        // 編集画面に遷移
        this.$router.push({ name: 'AdminProvisionalEpChallengeEdit', params: { id: this.provisional_ep_challenge.id } });
      },

      // 登録ボタン押下
      onSave() {
        this.$refs.exam_confirm_modal.open();
      },

      // 確認モーダル登録ボタン押下
      onSaveComfirmSend() {
        this.$loading.load(
          this.$auth.api.post(
            `admin/provisional_ep_challenges/${this.$route.params.id}/exam_update`, this.form
          ).then(res => {
            this.$refs.exam_confirm_modal.close();

            this.$bvToast.toast("登録しました", {
              variant: 'success',
              title: '完了'
            });
            this.$router.go({name: 'AdminProvisionalEpChallengeShow'});
          }).catch(err => {
            this.$refs.exam_confirm_modal.close();

            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 差分比較window表示
      onDiffView() {
        let resolvedRoute = this.$router.resolve({
          name: 'AdminProvisionalEpChallengeDiff',
          params: {id: this.provisional_ep_challenge.id}
        });
        window.open(resolvedRoute.href, 'preview','top=100,left=100,width=1000,height=700');
      },

      isObjectEmpty(object) {
        if(!object) {
          return true;
        }
        return !(Object.keys(object).length)
      },

      // 要修正理由 表示非表示判定
      isDispNoReason() {
        let result = false;

        // 編集時
        if(!!this.provisional_ep_challenge.id) {
          // 審査ステータスで「要修正」が選択されている
          if(this.form.provisional_ep_challenge.exam_status == 'modify') {
            result = true;
          }
          // 要修正理由が空ではない
          if(!!this.provisional_ep_challenge.no_reason) {
            result = true;
          }
        }

        return result;
      },

      // 審査詳細 表示非表示判定
      isDispExamInfo() {
        let result = false;

        // 審査ステータスが承認・再修正
        if(this.provisional_ep_challenge.exam_status == 'accepted' || this.provisional_ep_challenge.exam_status == 'modify') {
          result = true;
        }
        // 論理削除されている
        if(!!this.provisional_ep_challenge.deleted_at) {
          result = true;
        }

        return result;
      },

      // 審査登録 表示非表示判定
      isDispExamForm() {
        let result = false;

        // 論理削除されていない
        if(!this.provisional_ep_challenge.deleted_at) {
          // 審査ステータスが未承認・再登録
          if(this.provisional_ep_challenge.exam_status == 'undecided' || this.provisional_ep_challenge.exam_status == 'reregister') {
            result = true;
          }
        }

        return result;
      },

      // 公開ステータス、公開期間 表示非表示判定
      isDispPublicArea() {
        let result = false;

        // EPチャレンジが紐づいている
        if(!!this.provisional_ep_challenge.ep_challenge && !!this.provisional_ep_challenge.ep_challenge.id) {
          result = true;
        }
        else {
          // 審査ステータスで「承認」が選択されている
          if(this.form.provisional_ep_challenge.exam_status == 'accepted') {
            result = true;
          }
        }

        return result;
      },

      // 公開ステータスを更新
      updatePublicStatus() {
        this.form.provisional_ep_challenge.public_status = this.publicStatusText();
      },

      // 公開期間を比較して条件に合った公開ステータスのテキストを返却
      publicStatusText() {
        // 現在秒(ミリ秒)
        let current_time = new Date().getTime();

        // 日付の正規表現
        let date_pattern = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;
        // 時間の正規表現
        let time_pattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;

        let public_status_text = '';

        let release_date = this.form.provisional_ep_challenge.ep_challenge_attributes.release_date || '';
        let close_date   = this.form.provisional_ep_challenge.ep_challenge_attributes.close_date   || '';
        let release_time = this.form.provisional_ep_challenge.ep_challenge_attributes.release_time || '';
        let close_time   = this.form.provisional_ep_challenge.ep_challenge_attributes.close_time   || '';

        // 日付のフォーマットチェック
        release_date = release_date.match(date_pattern) ? release_date : null;
        close_date   = close_date.match(date_pattern)   ? close_date   : null;

        // 時間のフォーマットチェック
        release_time = release_time.match(time_pattern) ? release_time : null;
        close_time   = close_time.match(time_pattern)   ? close_time   : null;

        let release_at = null;
        let close_at = null;

        // 日付と時間を結合して開始日時作成
        // どちらか一方がnullの場合、開始日時はnull
        if(release_date != null && release_time != null) {
          let release_at_text = `${release_date} ${release_time}`;
          release_at = Date.parse(release_at_text);
        }

        // 日付と時間を結合して終了日時作成
        // どちらか一方がnullの場合、終了日時はnull
        if(close_date != null && close_time != null) {
          let close_at_text = `${close_date} ${close_time}`;
          close_at = Date.parse(close_at_text);
        }

        // 公開開始日時がnil または 現在日時が公開開始日時より未来
        if(release_at == null || release_at > current_time) {
          public_status_text = '公開待ち';
        }
        // 公開開始日時が現在日時より過去 かつ 公開終了日時がnil または 公開終了日時が現在日時より未来
        else if(release_at <= current_time && close_at == null || close_at >= current_time) {
          public_status_text = '公開';
        }
        else {
          public_status_text = '期限切れ';
        }

        return public_status_text;
      },

      // もっと見るボタン押下時
      onClickShowMoreAssets(){
        this.provisional_ep_challenge.is_show_more_assets = false;
      },
    }

  }
</script>