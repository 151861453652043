<template>
  <header class="top-header">
    <router-link v-if="$auth.urlPrefix == 'admin'" class="d-logo" :to="'/' + $auth.urlPrefix"><img v-bind:src="require('../../../images/header_logo.svg')" alt="Deloitte. デロイト トーマツ | six brain"></router-link>
    <router-link v-else-if="$auth.urlPrefix == 'ep'" class="d-logo" :to="'/' + $auth.urlPrefix + '/' + 'recommends'"><img v-bind:src="require('../../../images/header_logo.svg')" alt="Deloitte. デロイト トーマツ | six brain"></router-link>
    <router-link v-else-if="$auth.urlPrefix == 'su'" class="d-logo" :to="'/' + $auth.urlPrefix + '/' + 'challenges'"><img v-bind:src="require('../../../images/header_logo.svg')" alt="Deloitte. デロイト トーマツ | six brain"></router-link>
    <div class="user-profile">
      <p class="login-name">{{message}}</p>
      <div class="dropdown">
        <a class="dropdown-toggle" id="logout-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="dropdown()">
            <button v-if="icon" type="button"><img class="user-icon thumbnail-frame mx-auto" :src="icon" alt=""></button>
            <button v-else-if="$auth.urlPrefix == 'admin'" type="button"><img class="user-icon thumbnail-frame mx-auto" src="~images/dtvs_user_default.png" alt=""></button>
            <button v-else-if="$auth.accountType === 'ep_admin'" type="button"><img class="user-icon thumbnail-frame mx-auto" src="~images/ep_user_default.png" alt=""></button>
            <button v-else-if="$auth.accountType === 'ep_person_in_charge'" type="button"><img class="user-icon thumbnail-frame mx-auto" src="~images/ep_user_default.png" alt=""></button>
            <button v-else-if="$auth.accountType === 'su_user'" type="button"><img class="user-icon thumbnail-frame mx-auto" src="~images/su_user_default.png" alt=""></button>
            <button v-else-if="$auth.accountType === 'su_person_in_charge'" type="button"><img class="user-icon thumbnail-frame mx-auto" src="~images/su_user_default.png" alt=""></button>
            <!-- TODO: ep_person_in_chargeが追加され次第、画像差し替え -->
            <button v-else type="button"><img class="user-icon thumbnail-frame mx-auto" src="~images/ep_user_default.png" alt=""></button>
        </a>

        <div class="dropdown-menu py-3" :ref="'dropdown_menu'" aria-labelledby="logout-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-65px, 30px, 0px);" v-if="this.$auth.isLoggedIn">
          <b-button class="btn btn-white btn-sm rounded-pill" type="button" @click="$router.push('/' + $auth.urlPrefix + '/logout')">ログアウト</b-button>
        </div>
      </div>
    </div>
    <div @click="dropdownClose()" v-show="dropdownShow" style="opacity: 0; position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 1;"></div>
  </header>
</template>

<script>
export default {
  data () {
    return {
      dropdownShow: false,
    }
  },
  props: {
    companyname: String,
    message: String,
    icon: String,
    notificationCount: Number
  },
  methods: {
    dropdown () {
      if(this.$refs.dropdown_menu.classList.contains('show')) {
        this.$refs.dropdown_menu.classList.remove('show')
        this.dropdownShow = false
      }else{
        this.$refs.dropdown_menu.classList.add('show')
        this.dropdownShow = true
      }
    },
    dropdownClose () {
      this.$refs.dropdown_menu.classList.remove('show')
      this.dropdownShow = false
    }
  }
}
</script>