<template>
  <div class="side-scroll-container">
    <nav>
      <ul class="list-unstyled">
        <li @click="routerPush('SuChallengeIndex')" :class="[currentPage.includes('challenges') ? currentClass : '']">
          <a><i></i><span>チャレンジ閲覧</span></a>
        </li>
        <li @click="routerPush('SuProvisionalSuLatestInfoIndex')" :class="[currentPage.includes('provisional_su_latest_infos') ? currentClass : '']">
          <a><i></i><span>最新情報一覧</span></a>
        </li>
        <li @click="routerPush('SuMessageRoom', {room_id: message_room_id})" :class="[currentPage.includes('message') ? currentClass:'']">
          <a><i></i><span>メッセージ</span></a>
        </li>
        <li @click="routerPush('SuSuCompanyDetail')" :class="[currentPage.includes('su_company') ? activeClass : '']" >
          <a><i></i><span>アカウント情報</span></a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentClass: 'current',
      activeClass: 'active',
    };
  },
  computed: {
    currentPage() {
      return this.$route.path
    },isPbShowRoute() {
      return this.$route.name === 'SuChallengePbShow';
    }
  },
  props: ['message_room_id'],

  methods: {
    // 画面遷移処理
    routerPush(route_name, params = {}){
      // 遷移元と遷移先のルート名が同じ場合は何もしない
      if (this.$route.name != route_name){
        this.$router.push({name: route_name, params: params} , () => {}, () => {});
      }
    }
  },
}
</script>
