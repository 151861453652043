<template>
  <div class="main-area">
    <main>
      <section class="main-title">
        <h1>チャレンジ情報閲覧</h1>
      </section>

      <!-- チャレンジ情報-->
      <section v-if="challenge.is_public" class="article-container">
        <article class="white-block reading-block">
          <!-- メインビジュアル画像 -->
          <div class="reading-visual">
            <template v-if="challenge.presigned_challenge_main_image_url">
              <a class="img-box" :href="challenge.presigned_challenge_main_image_url"
                data-lightbox="challenge_image" :data-title="challenge.main_image_title">
                <img
                  :src="challenge.presigned_challenge_main_image_url"
                  :alt="challenge.presigned_challenge_main_image_filename">
              </a>
              <div class="reading-visual-caption">{{ challenge.main_image_title }}</div>
            </template>
            <template v-else>
              <a class="img-box" href="#">
                <img
                  src="~images/image_default.jpg"
                  alt="デフォルト画像"
                />
              </a>
            </template>
          </div>
          <!-- 投稿日時 -->
          <div class="reading-bar">
            <small class="reading-date text-secondary">{{ challenge.release_at | format.datetime }}</small>
          </div>
          <!-- タイトル -->
          <h2 class="reading-title">{{ challenge.title }}</h2>
          <!-- 最新情報企業 -->
          <dl class="reading-body border-bottom">
            <dt>
              <img v-if="challenge.is_challenge_manager_image"
                :src="challenge.presigned_challenge_manager_image_url"
                :alt="challenge.presigned_challenge_manager_image_filename"
                class="thumbnail-frame img40"
              />
              <img v-else
                src="~images/ep_user_default.png"
                alt="画像が選択されていません"
                class="thumbnail-frame img40"
              />
            </dt>
            <dd>
              <p class="company-name">{{ challenge.ep_company_name_department_name }}</p>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>私たちのチャレンジ</dt>
            <dd class="u-pre-wrap" v-html="challenge.detail"></dd>
          </dl>
          <dl class="reading-item" v-if="challenge.sub1_image_id || challenge.sub2_image_id">
            <dt>イメージ</dt>
            <dd class="d-flex">
              <!-- 画像（サブビジュアル1） -->
              <figure v-if="challenge.sub1_image_id">
                <a class="img-box" :href="challenge.presigned_challenge_sub1_image_url"
                  data-lightbox="challenge_image" :data-title="challenge.sub1_image_title">
                  <img
                    :src="challenge.presigned_challenge_sub1_image_url"
                    :alt="challenge.presigned_challenge_sub1_image_filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ challenge.sub1_image_title }}</figcaption>
              </figure>
              <!-- 画像（サブビジュアル2） -->
              <figure class="mr-3" v-if="challenge.sub2_image_id">
                <a class="img-box" :href="challenge.presigned_challenge_sub2_image_url"
                  data-lightbox="challenge_image" :data-title="challenge.sub2_image_title">
                  <img
                    :src="challenge.presigned_challenge_sub2_image_url"
                    :alt="challenge.presigned_challenge_sub2_image_filename"
                  >
                </a>
                <figcaption class="reading-visual-caption">{{ challenge.sub2_image_title }}</figcaption>
              </figure>
            </dd>
          </dl>
          <dl class="reading-item">
            <dt>パートナー企業様にご提供できる価値</dt>
            <dd>
              <template v-if="challenge.is_show_more_assets">
                <div v-for="asset in challenge.asset_masters.slice(0, 10)" :key="asset.asset_master_id" class="task-labels">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
                <div class="btn-container text-right">
                  <button class="js-view-trigger btn-text d-show" type="button" @click="onClickShowMoreAssets()">
                    もっと見る
                  </button>
                </div>
              </template>
              <template v-else>
                <div v-for="asset in challenge.asset_masters" :key="asset.asset_master_id" class="task-labels">
                  <p class="task-labels-parent">
                    {{ asset.name }}
                  </p>
                </div>
              </template>
            </dd>
          </dl>

          <!-- チャレンジのステージ -->
          <dl class="reading-item">
            <dt>このチャレンジのステータス</dt>
            <dd>
              <div class="status-list">

                <!-- アイデア段階 -->
                <div class="status-item">
                  <div v-if="challenge.is_idea_stage">
                    <p class="status-label active">アイデア段階</p>
                    <div class="img-box"><img src="~images/status_img1_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">アイデア段階</p>
                    <div class="img-box"><img src="~images/status_img1.svg" alt=""></div>
                  </div>
                </div>

                <!-- PoC段階 -->
                <div class="status-item">
                  <div v-if="challenge.is_poc_stage">
                    <p class="status-label active">PoC段階</p>
                    <div class="img-box"><img src="~images/status_img2_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">PoC段階</p>
                    <div class="img-box"><img src="~images/status_img2.svg" alt=""></div>
                  </div>
                </div>

                <!-- 事業化段階 -->
                <div class="status-item">
                  <div v-if="challenge.is_commercial_stage">
                    <p class="status-label active">事業化段階</p>
                    <div class="img-box"><img src="~images/status_img3_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">事業化段階</p>
                    <div class="img-box"><img src="~images/status_img3.svg" alt=""></div>
                  </div>
                </div>

                <!-- その他 -->
                <div class="status-item">
                  <div v-if="challenge.is_other_stage">
                    <p class="status-label active">その他</p>
                    <div class="img-box"><img src="~images/status_img4_on.svg" alt=""></div>
                  </div>
                  <div v-else>
                    <p class="status-label">その他</p>
                    <div class="img-box"><img src="~images/status_img4.svg" alt=""></div>
                  </div>
                </div>

              </div>
            </dd>
          </dl>

          <dl class="reading-item">
            <dt>企業情報（私たちについて）</dt>
            <dd class="u-pre-wrap" v-text="challenge.ep_company_business_summary"></dd>
          </dl>
        </article>

        <aside class="chapter white-block mb-4">
          <h3 class="chapter-label mb-3">担当者情報</h3>
          <div class="d-flex p-3">
            <!-- チャレンジの担当者イメージ -->
            <img v-if="challenge.is_challenge_manager_image"
              :src="challenge.presigned_challenge_manager_image_url"
              :alt="challenge.presigned_challenge_manager_image_filename"
              class="thumbnail-frame img80"
            />
            <img v-else
              src="~images/ep_user_default.png"
              alt="画像が選択されていません"
              class="thumbnail-frame img80"
            />
            <div class="ml-3">
              <p class="h5">{{ challenge.challenge_manager_name }}</p>
              <p class="text-box mt-1 u-pre-wrap" v-text="challenge.challenge_manager_profile"></p>
            </div>
          </div>
        </aside>
      </section>

      <!-- チャレンジ情報　スポンサー枠-->
      <section v-else class="article-container mt-3">
        <article class="white-block reading-block">
          <section class="article-body">
            <div class="article-lead">
            公開されていません
            </div>
          </section>
        </article>
      </section>

    </main>

    <!-- 興味あり-->
    <div class="fixed-btn-container-bottom" v-if="challenge.is_public">
      <div class="inner">
        <!-- 興味ありを押している時は「active」クラスを付けてください-->
        <template v-if="challenge.is_interested">
          <button @click="onInterest()" class="button-square btn-block button-like active" type="button"><i class="far fa-thumbs-up"></i><span>取り消し</span></button>
        </template>
        <template v-else>
          <button @click="onInterest()" class="button-square btn-block button-like" type="button"><i class="far fa-thumbs-up"></i><span>興味あり</span></button>
        </template>
      </div>
    </div>

    <div class="btn-container-under" v-if="challenge.is_public"><a href="#" @click.prevent.stop="onLikeHelp()"><i class="fas fa-info-circle mr-1"></i><span>興味ありって？</span></a></div>
    <!-- 興味あり-->

    <FooterComponent />

    <!-- ガイド-->
    <b-modal
      v-model="like_send_help"
      id="guide"
      aria-labelledby="guide-Label"
      title="興味ありって？"
      centered
      hide-header
      hide-footer
      size="sm"
      :no-fade="isNoFade"
    >
      <div class="modal-body">
        <h3 class="chapter-label mb-3"><i class="fas fa-info-circle mr-1"></i>興味ありって？</h3>
        <div class="mx-auto">
          <div class="img-box"><img src="~images/like.svg" alt=""></div>
        </div>
        <div class="my-3">
          <p class="text-box mt-1">気になった案件に対して「興味あり」登録をすることで、企業からのオファーを受け取る可能性が高くなります。気軽に「興味あり登録」をしてみましょう！</p>
        </div>
        <div class="text-center">
          <button type="button" data-dismiss="modal" @click="onLikeHelpHide()"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </b-modal>
    <!-- / ガイド-->

    <!-- 興味あり-->
    <b-modal
      v-model="show_like_modal"
      id="like"
      aria-labelledby="like-Label"
      title="興味あり"
      centered
      hide-header
      hide-footer
      size="sm"
      :no-fade="isNoFade"
    >
      <div>
        <p class="mt-3 text-center">興味ありを有難うございます！<br>こちらの企業様へ貴社の情報をお届けします。<br>企業様よりオファーが届いた際は<br>通知が届きますので宜しくお願いします。</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-cancel" type="button" @click="backToPage()">チャレンジ一覧に戻る</button>
      </div>
    </b-modal>
    <!-- / 興味あり-->
     <!-- 会員登録-->
    <b-modal
      v-model="show_regist_modal"
      id="like"
      aria-labelledby="like-Label"
      title="会員登録"
      centered
      hide-header
      hide-footer
      size="sm"
      :no-fade="isNoFade"
    >
      <div>
        <p class="mt-3 text-center">興味ありを有難うございます！<br>こちらの企業様へ貴社の情報をお届けします。<br>まずはアカウント登録より宜しくお願いします。</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-cancel" type="button" @click="goToRegistPage()">アカウント登録に遷移する</button>
      </div>
    </b-modal>
    <!-- / 会員登録-->

  </div>
</template>

<script>
import FooterComponent from '../../../common/layouts/footer';
import SlickCarousel from "../../../common/SlickCarouselMixin"
import ScrollMixin from '../../../common/ScrollMixin'

export default {
  components: {FooterComponent},

  mixins: [SlickCarousel, ScrollMixin],

  metaInfo: {
    title: 'チャレンジ情報閲覧',
  },

  data() {
    return {
      challenge_id: this.$route.params.challenge_id,
      challenge: {},
      other_challenges: {},
      previous_challenge_id: null,
      show_like_button: false,
      show_like_modal: false,
      show_regist_modal: false,
      like_send_help: false
    }
  },

  props: { isNoFade: Boolean },

  // メソッド定義
  methods: {
    // データ取得処理
    getChallenge() {
      this.$loading.load(
        this.$auth.api.get(
          'su/challenges/' + this.challenge_id + '/pb_show', {
            params: {}
          }
        ).then(res => {
          this.challenge = res.data.challenge;
          this.other_challenges = res.data.other_challenges;
        }).catch(err => {
          this.$errorHandlers.initial(err);
        })
      )
    },

    // 他チャレンジ押下時
    onClickOtherChallenge(other_challenge) {
      this.challenge_id = other_challenge.id
      let resolvedRoute = this.$router.resolve({
          name: 'SuChallengePbShow',
          params: {challenge_id: other_challenge.id}
        });
      window.open(resolvedRoute.href)
    },

    // 興味ありボタン押下
    onInterest() {
      // このチャレンジがすでに興味あり済みか
      if (this.challenge.is_interested) {
        // 興味なし→あり
        this.deleteInterest();
      } else {
        // 未ログインなら会員登録画面へ遷移し、処理を中断
        if (!this.$auth.isLoggedIn) {
          this.show_regist_modal = true;
          return
        }
        // 興味あり→なし
        this.insertInterest();
      }
    },

    // レコード追加リクエスト
    insertInterest() {
      this.$loading.load(
        this.$auth.api.post(`/su/challenges/register_interest`, {id: this.challenge.id}
        ).then(res => {
          this.challenge.is_interested = !this.challenge.is_interested;
          this.show_like_modal = true;
        }).catch(err => {
          if (err.response.status == 500) {
            this.$errorHandlers.initial(err);
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      )
    },

    // レコード削除リクエスト
    deleteInterest() {
      this.$loading.load(
        this.$auth.api.delete(`/su/challenges/${this.challenge.id}/delete_interest`
        ).then(res => {
          this.challenge.is_interested = !this.challenge.is_interested;
          this.show_like_modal = false;
        }).catch(err => {
          if (err.response.status == 500) {
            this.$errorHandlers.initial(err);
          } else {
            this.$errorHandlers.ajax(err);
          }
        })
      )
    },

    // 戻るボタン
    // 一覧→詳細　一覧に遷移
    // 詳細→詳細　一個前の詳細に遷移
    backToPage() {
      if (!!history.back()) {
        history.back();
      } else {
        this.$router.push({name: 'SuChallengeIndex'})
      }
    },
    goToRegistPage() {
      this.$router.push('/su/request_flow1_edit');
      return;
    },
    // リコメンド対応とはモーダル
      onLikeHelp() {
        this.like_send_help = true;
      },

      // リコメンド対応とはモーダル閉じる
      onLikeHelpHide() {
        this.like_send_help = false;
      },

    // もっと見るボタン押下時
    onClickShowMoreAssets(){
      this.challenge.is_show_more_assets = false;
    },
  },

  // インスタンス生成後、リアクティブデータ初期化後
  created() {
    if (!!this.challenge_id) {
      this.getChallenge();
    }
  },

  // route.push時に実行
  beforeRouteUpdate(to, from, next) {
    this.previous_challenge_id = from.params.challenge_id
  },

  //Scroll時のイベント
  onScroll() {
    this.show_like_button = (window.scrollY > 400);
  }
}
</script>
<style>
.lb-nav a.lb-prev, .lb-nav a.lb-next {
  width: 50%;
}
.fadeIn {
  animation: fadeIn 0.4s;
}
</style>