<template>
  <div>
    <router-view
      :provisional_ep_challenge="provisional_ep_challenge"
      :asset_masters="asset_masters"
      :ep_industry_asset_masters="ep_industry_asset_masters"
      :industry_masters="industry_masters"
      :challenge_stages="challenge_stages"
      :accept_extensions="accept_extensions"
      :form.sync="form"
      :main_image.sync="main_image"
      :sub1_image.sync="sub1_image"
      :sub2_image.sync="sub2_image"
      :validation_errors="validation_errors"
      :onTemporarily="onTemporarily"
      :onConfirm="onConfirm"
      :onSave="onSave"
      :onFix="onFix"
      :onBack="onBack"
      :is_form_changed="is_form_changed"
    >
    </router-view>
    <CompletedModal
      ref="completed_modal"
      :onModalClose="moveAfterSave"
      :action_text="'更新'"
      :additional_text="'<br>DTVSが内容について確認を行い、<br>スタートアップへ公開されます。<br>審査完了までお待ちください。'"
      :isNoFade="isNoFade"
    />
  </div>
</template>

<script>
  import CompletedModal from '../../../common/pages/CompletedModal.vue';
  export default {
    metaInfo: {
      title: 'チャレンジ編集',
    },

    components: {
      CompletedModal,
    },

    data() {
      return {
        provisional_ep_challenge: {},
        // 承認済みアセット一覧
        asset_masters: [],
        // 大手企業の業界に紐づく承認済みアセット一覧
        ep_industry_asset_masters: [],
        // 業界一覧
        industry_masters: [],
        // ステージ一覧
        challenge_stages: {},
        // 許容拡張子
        accept_extensions: [],

        was_valid: false,
        validation_errors: {},
        previous_route: null,
        is_form_changed: false,
        form: {
          provisional_ep_challenge: {
            ep_company_id: '',
            title:  '',
            detail: '',
            before_detail_image_ids: [],
            registed_type: '',
            is_public: '',
            exam_status: '',
            challenge_stage: '',
            updated_at: '',
            // メイン画像
            main_image_title: '',
            // サブ1画像
            sub1_image_title: '',
            // サブ2画像
            sub2_image_title: '',
            // メイン画像
            main_image_attributes: {
              // id: ''
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // サブ画像1
            sub1_image_attributes: {
              // id: ''
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // サブ画像2
            sub2_image_attributes: {
              // id: ''
              // 画像新規アップロード情報
              // filename: '',
              // size: '',
              // upload_image: '',
            },
            // 選択アセット
            provisional_ep_challenge_asset_masters_attributes: [
              // {
              //   id: '',
              //   asset_master_id: '',
              // }
            ],
            // 削除アセット
            destroy_provisional_ep_challenge_asset_masters_attributes: [
              // {
              //   id: '',
              //   asset_master_id: '',
              // }
            ],
            // 登録予定のアセット
            asset_masters_attributes: [
              // {
              //   id: '',
              //   name: '',
              //   // 編集処理用,
              //   is_edit: false,
              //   updated_at: '',
              // }
            ],
            // 業種
            provisional_ep_challenge_industry_masters_attributes: [
              // {
              //   id: '',
              //   industry_master_id: '',
              // }
            ],
            // 削除業界
            destroy_provisional_ep_challenge_industry_masters_attributes: [
              // {
              //   id: '',
              //   industry_master_id: '',
              // }
            ],
            // 大手企業
            ep_company_attributes: {
              id: '',
              // 私たちについて
              business_summary: '',
              updated_at: '',
            },
          },
        },
        // メイン画像(表示用)
        main_image: {
          src: '',
          alt: '',
        },
        // サブ1画像(表示用)
        sub1_image: {
          src: '',
          alt: '',
        },
        // サブ2画像(表示用)
        sub2_image: {
          src: '',
          alt: '',
        },
      }
    },

    props: { isNoFade: Boolean },

    // インスタンス生成後、リアクティブデータ初期化後
    created() {
      this.init();
    },

    // route.push時に実行
    beforeRouteUpdate (to, from, next) {
      next();
    },

    // コンポーネントを描画するルートが確立する前に呼ばれる
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.previous_route = from;
      });
    },

    // メソッド定義
    methods: {
      // 初期化
      init() {
        this.getProvisionalEpChallenge();
      },

      getProvisionalEpChallenge() {
        this.$loading.load(
          this.$auth.api.get(
            `ep/provisional_ep_challenges/form_init?provisional_ep_challenge_id=${this.$route.params.id}`
          ).then(res => {
            this.provisional_ep_challenge = res.data.provisional_ep_challenge;
            // 承認済みアセットマスタ一覧
            this.asset_masters = res.data.approval_asset_masters;
            // 大手企業のメイン業界に紐づくアセットマスタ
            this.ep_industry_asset_masters = res.data.ep_industry_asset_masters;
            // 業界マスタ
            this.industry_masters = res.data.industry_masters;
            // ステージ
            this.challenge_stages = res.data.challenge_stages;
            // 許容拡張子
            this.accept_extensions = res.data.accept_extensions;

            // 大手企業ID
            this.form.provisional_ep_challenge.ep_company_id = this.provisional_ep_challenge.ep_company.id;

            // 仮登録EPチャレンジ
            this.form.provisional_ep_challenge.id     = this.provisional_ep_challenge.id;
            this.form.provisional_ep_challenge.title  = this.provisional_ep_challenge.title;
            this.form.provisional_ep_challenge.detail = this.provisional_ep_challenge.detail || '';
            this.form.provisional_ep_challenge.before_detail_image_ids = this.provisional_ep_challenge.before_detail_image_ids;
            this.form.provisional_ep_challenge.challenge_stage = this.provisional_ep_challenge.challenge_stage || '';
            this.form.provisional_ep_challenge.updated_at      = this.provisional_ep_challenge.updated_at;
            // 公開枠対応
            this.form.provisional_ep_challenge.is_public = this.provisional_ep_challenge.is_public;

            // 仮登録EPチャレンジ アセット
            Object.entries(this.provisional_ep_challenge.asset_masters || {}).forEach(([key, asset_master]) => {
              // 承認済みか？
              if(asset_master.is_accepted) {
                // 紐づくアセット
                this.form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes.push(
                  {
                    'id': asset_master.relation_id,
                    'asset_master_id': asset_master.id,
                  }
                );
              }
              // 未承認か？
              else if(asset_master.is_undecided) {
                // 登録予定アセット
                this.form.provisional_ep_challenge.asset_masters_attributes.push(
                  {
                    'id': asset_master.id,
                    'name': asset_master.name,
                    'is_edit': false,
                    'relation_id': asset_master.relation_id,
                    'updated_at': asset_master.updated_at,
                  }
                );
              }
            });

            // 仮登録EPチャレンジ 業界
            Object.entries(this.provisional_ep_challenge.industry_masters || {}).forEach(([key, industry_master]) => {
              // 紐づく業界
              this.form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes.push(
                {
                  'id': industry_master.relation_id,
                  'industry_master_id': industry_master.id,
                }
              );
            });

            // 仮登録EPチャレンジ 画像
            this.form.provisional_ep_challenge.main_image_title                   = this.provisional_ep_challenge.main_image_title    || '';
            this.form.provisional_ep_challenge.main_image_attributes.id           = this.provisional_ep_challenge.main_image.id       || null;
            this.form.provisional_ep_challenge.main_image_attributes.filename     = this.provisional_ep_challenge.main_image.filename || '';
            this.form.provisional_ep_challenge.main_image_attributes.upload_image = this.provisional_ep_challenge.main_image.presigned_url || '';

            this.form.provisional_ep_challenge.sub1_image_title                   = this.provisional_ep_challenge.sub1_image_title    || '';
            this.form.provisional_ep_challenge.sub1_image_attributes.id           = this.provisional_ep_challenge.sub1_image.id       || null;
            this.form.provisional_ep_challenge.sub1_image_attributes.filename     = this.provisional_ep_challenge.sub1_image.filename || '';
            this.form.provisional_ep_challenge.sub1_image_attributes.upload_image = this.provisional_ep_challenge.sub1_image.presigned_url || '';

            this.form.provisional_ep_challenge.sub2_image_title                   = this.provisional_ep_challenge.sub2_image_title    || '';
            this.form.provisional_ep_challenge.sub2_image_attributes.id           = this.provisional_ep_challenge.sub2_image.id       || null;
            this.form.provisional_ep_challenge.sub2_image_attributes.filename     = this.provisional_ep_challenge.sub2_image.filename || '';
            this.form.provisional_ep_challenge.sub2_image_attributes.upload_image = this.provisional_ep_challenge.sub2_image.presigned_url || '';

            // 大手企業 私たちについて
            this.form.provisional_ep_challenge.ep_company_attributes.id = this.provisional_ep_challenge.ep_company.id;
            this.form.provisional_ep_challenge.ep_company_attributes.business_summary = this.provisional_ep_challenge.ep_company.business_summary;

            // 大手企業 更新日時
            this.form.provisional_ep_challenge.ep_company_attributes.updated_at = this.provisional_ep_challenge.ep_company.updated_at;

            // メイン画像 初期化
            this.main_image.src = this.provisional_ep_challenge.main_image.presigned_url || ''
            this.main_image.alt = this.form.provisional_ep_challenge.main_image_attributes.filename;

            // サブ1画像 初期化
            this.sub1_image.src = this.provisional_ep_challenge.sub1_image.presigned_url || ''
            this.sub1_image.alt = this.form.provisional_ep_challenge.sub1_image_attributes.filename;

            // // サブ2画像 初期化
            this.sub2_image.src = this.provisional_ep_challenge.sub2_image.presigned_url || ''
            this.sub2_image.alt = this.form.provisional_ep_challenge.sub2_image_attributes.filename;

            // 入力項目の監視を開始（非同期処理でフォーム変更と監視開始のタイミングが前後するためここで実施する）
            this.$watch('form', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('main_image', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('sub1_image', function(){ this.is_form_changed = true }, { deep: true });
            this.$watch('sub2_image', function(){ this.is_form_changed = true }, { deep: true });

          }).catch(err => {
            this.$errorHandlers.initial(err);
          })
        );
      },

      onTemporarily() {
        // 下書きボタン経由のためtrueをonConfirmに渡す
        this.onConfirm(true);
      },

      // 確認ボタン押下
      onConfirm(is_draft = false) {
        // 登録種別 編集
        this.form.provisional_ep_challenge.registed_type = 'edit'

        // 下書き
        if(is_draft) {
          // 審査ステータス 下書き
          this.form.provisional_ep_challenge.exam_status = 'draft';
          // 登録種別 登録
          this.form.provisional_ep_challenge.registed_type = 'registration'
        }
        // 下書き
        else if(this.provisional_ep_challenge.is_draft) {
          // 審査ステータス 未承認
          this.form.provisional_ep_challenge.exam_status = 'undecided';
          // 登録種別 登録
          this.form.provisional_ep_challenge.registed_type = 'registration'
        }
        // 承認
        else if(this.provisional_ep_challenge.is_accepted) {
          // 審査ステータス 未承認
          this.form.provisional_ep_challenge.exam_status = 'undecided';
        }
        // 要修正
        else if(this.provisional_ep_challenge.is_modify) {
          // 審査ステータス 要登録
          this.form.provisional_ep_challenge.exam_status = 'reregister';
        }
        // 未承認
        else if(this.provisional_ep_challenge.is_undecided){
          // 審査ステータス 未承認
          this.form.provisional_ep_challenge.exam_status = 'undecided';
        }
        // 再登録
        else if(this.provisional_ep_challenge.is_reregister){
          // 審査ステータス 要申請
          this.form.provisional_ep_challenge.exam_status = 'reregister';
        }

        // 値をpostする形にするため編集をコピー
        let post_form = JSON.parse(JSON.stringify(this.form));

        // 登録するアセットと削除するアセットのパラメータを結合
        let provisional_ep_challenge_asset_masters_attributes = post_form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes
        let destroy_provisional_ep_challenge_asset_masters_attributes = post_form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes
        let concat_provisional_ep_challenge_asset_masters_attributes = provisional_ep_challenge_asset_masters_attributes.concat(destroy_provisional_ep_challenge_asset_masters_attributes);
        // 削除するアセットのパラメータを削除(結合したので不要)
        this.$delete(post_form.provisional_ep_challenge, 'destroy_provisional_ep_challenge_asset_masters_attributes');
        // postする値に結合したパラメータを設定
        post_form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes = concat_provisional_ep_challenge_asset_masters_attributes

        // 登録する業界と削除する業界のパラメータを結合
        let provisional_ep_challenge_industry_masters_attributes = post_form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes
        let destroy_provisional_ep_challenge_industry_masters_attributes = post_form.provisional_ep_challenge.destroy_provisional_ep_challenge_industry_masters_attributes
        let concat_provisional_ep_challenge_industry_masters_attributes = provisional_ep_challenge_industry_masters_attributes.concat(destroy_provisional_ep_challenge_industry_masters_attributes);
        // 削除する業界のパラメータを削除(結合したので不要になった)
        this.$delete(post_form.provisional_ep_challenge, 'destroy_provisional_ep_challenge_industry_masters_attributes');
        // postする値に結合したパラメータを設定
        post_form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes = concat_provisional_ep_challenge_industry_masters_attributes

        this.$loading.load(
          this.$auth.api.post(
            'ep/provisional_ep_challenges/confirm?', post_form
          ).then(res => {
            // 確認画面を表示
            this.$router.push({name: 'EpProvisionalEpChallengeEditConfirm'});
          }).catch(err => {
            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 保存ボタン押下
      onSave() {
        // 値をpostする形にするため編集をコピー
        let post_form = JSON.parse(JSON.stringify(this.form));

        // 登録する業界と削除するアセットのパラメータを結合
        let provisional_ep_challenge_asset_masters_attributes = post_form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes
        let destroy_provisional_ep_challenge_asset_masters_attributes = post_form.provisional_ep_challenge.destroy_provisional_ep_challenge_asset_masters_attributes
        let concat_provisional_ep_challenge_asset_masters_attributes = provisional_ep_challenge_asset_masters_attributes.concat(destroy_provisional_ep_challenge_asset_masters_attributes);
        // 削除するアセットのパラメータを削除(結合したので不要)
        this.$delete(post_form.provisional_ep_challenge, 'destroy_provisional_ep_challenge_asset_masters_attributes');
        // postする値に結合したパラメータを設定
        post_form.provisional_ep_challenge.provisional_ep_challenge_asset_masters_attributes = concat_provisional_ep_challenge_asset_masters_attributes

        // 登録する業界と削除する業界のパラメータを結合
        let provisional_ep_challenge_industry_masters_attributes = post_form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes
        let destroy_provisional_ep_challenge_industry_masters_attributes = post_form.provisional_ep_challenge.destroy_provisional_ep_challenge_industry_masters_attributes
        let concat_provisional_ep_challenge_industry_masters_attributes = provisional_ep_challenge_industry_masters_attributes.concat(destroy_provisional_ep_challenge_industry_masters_attributes);
        // 削除する業界のパラメータを削除(結合したので不要)
        this.$delete(post_form.provisional_ep_challenge, 'destroy_provisional_ep_challenge_industry_masters_attributes');
        // postする値に結合したパラメータを設定
        post_form.provisional_ep_challenge.provisional_ep_challenge_industry_masters_attributes = concat_provisional_ep_challenge_industry_masters_attributes

        this.$loading.load(
          this.$auth.api.patch(
            `ep/provisional_ep_challenges/${this.$route.params.id}`, post_form
          ).then(res => {
            // 下書き保存以外でチャレンジ情報を更新した場合は更新完了モーダル表示
            if (this.form.provisional_ep_challenge.exam_status != 'draft' && res.data.is_edit_challenge)
              this.$refs.completed_modal.open();
            else{
              this.moveAfterSave();
            }
          }).catch(err => {
            // バリデーションエラー
            if (err.response.status == 422) {
              this.validation_errors = err.response.data.errors;
            }
            else if (err.response.status == 500) {
              this.$errorHandlers.initial(err);
            }
            else {
              this.$errorHandlers.ajax(err);
            }
          })
        );
      },

      // 保存後の画面遷移処理
      moveAfterSave(){
        if (!!this.previous_route && this.previous_route.name === 'EpProvisionalEpChallengeIndex') {
          this.$router.push({path: this.previous_route.fullPath, query: {toast_type: 'updated'}});
        }
        else if (this.previous_route.name === 'EpProvisionalEpChallengeShow') {
          this.$router.push({name: 'EpProvisionalEpChallengeShow',  query: {toast_type: 'updated'}});
        }
        else {
          this.$router.push({name: 'EpProvisionalEpChallengeIndex', query: {toast_type: 'updated'}});
        }
      },

      // 修正ボタン押下時処理
      onFix() {
        this.$router.push({name: 'EpProvisionalEpChallengeEdit'});
      },

      // 戻るボタン押下時処理
      onBack() {
        if (!!this.previous_route && this.previous_route.name === 'EpProvisionalEpChallengeIndex') {
          this.$router.push({path: this.previous_route.fullPath});
        }
        else if (!!this.previous_route && this.previous_route.name === 'EpProvisionalEpChallengeShow') {
          this.$router.push({path: this.previous_route.fullPath});
        }
        else {
          this.$router.push({name: 'EpProvisionalEpChallengeIndex'});
        }
      },
    },
  }

</script>
